<template>
    <div class="deposit-modal-header">
        <div class="deposit-modal-header__heading">
            <div class="deposit-modal-header__group">
                <div class="deposit-modal-header__group-label">
                    {{ $t("components.common.product") }}
                </div>
                <div class="deposit-modal-header__group-text">
                    {{ $t("components.common.contribution") }}: {{ htmlToString(model.depName) }}
                </div>
            </div>
        </div>
        <div class="deposit-modal__info-row">
            <ProblemDescriptionItem
                class="deposit-modal__info"
                name="components.common.fullName"
                :value="model.clientName"
                type="card"
            />
            <ProblemDescriptionItem
                class="deposit-modal__info"
                name="components.common.amount"
                :value="
                    model.amount &&
                    formatSum(model.amount, true) + ' ' + currency
                "
                type="card"
            />
            <ProblemDescriptionItem
                class="deposit-modal__info"
                name="components.common.dateStartOrEnd"
                :value="model.openDate + ' / ' + model.closingDate"
                type="card"
            />
            <ProblemDescriptionItem
                class="deposit-modal__info"
                name="components.common.percent"
                :value="model.percent && model.percent + '%'"
                type="card"
            />
            <ProblemDescriptionItem
                class="deposit-modal__info"
                name="components.common.branchCode"
                :value="model.filialCode"
                type="card"
            />
            <ProblemDescriptionItem
                class="deposit-modal__info"
                name="components.common.depositID"
                :value="model.savDepId"
                type="card"
            />
        </div>
    </div>
</template>

<script>
import ProblemDescriptionItem from "../../partials/ProblemDescriptionItem.vue";
import { decodeHtmlToString } from "../../../helpers/other";

export default {
    name: "LoanModalHeader",

    props: ["model", "currency", "values", "formatSum"],

    components: {
        ProblemDescriptionItem,
    },

    methods: {
        htmlToString(text) {
            return decodeHtmlToString(text);
        },
    },
};
</script>

<style lang="scss">
.deposit-modal-header {
    margin-bottom: 40px;
}

.deposit-modal-header__heading {
    display: flex;
    gap: 120px;
    margin-bottom: 24px;

    .deposit-modal-header__group-text {
        font-size: 28px;
        font-weight: 500;
    }
}

.deposit-modal-header__group-label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 6px;
    color: #949494;
}

.deposit-modal-header__group-text {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}
</style>
