<template>
    <ModalWrapper class="deposit-modal" @scroll="onScroll" ref="modal" #default="{close}">
        <transition name="deposit-float-header">
            <div v-if="headerActive" class="deposit-modal__float-header">
                <DepositModalHeader class="m-0" :model="modals.deposit.model" :format-sum="$parent.formatSum" :currency="currency" :values="values"/>
                <div class="deposit-modal__close" @click="close">
                    <AppIcon icon="close"/>
                </div>
            </div>
        </transition>
        <div class="deposit-modal__window">
            <DepositModalHeader :model="modals.deposit.model" :format-sum="$parent.formatSum" :currency="currency" :values="values"/>
            <div class="deposit-modal__large">
                <AppTabs v-model="depositTab" :items="depositTabs" @change="onTabChange" theme="processing">
                    <template #body.1>
                        <div class="deposit-modal__body">
                            <template v-if="!modals.deposit.account.info">
                                <div class="block-wrap deposit-modal__block">
                                    <ProblemDescriptionItem name="components.common.check" :value="modals.deposit.model.account" type="card" clickable @click-value="$parent.openAccount(modals.deposit.model.account, 'deposit')"/>
                                    <ProblemDescriptionItem name="components.common.interestAccount" :value="modals.deposit.model.accountPercent" type="card" @click-value="$parent.openAccount(modals.deposit.model.accountPercent, 'deposit')"/>
                                </div>
                                <div v-if="modals.deposit.model" class="deposit-modal__wrap">
                                    <ProblemDescriptionItem class="deposit-modal__item" name="components.common.name" :value="modals.deposit.model.depName" type="card"/>
                                    <ProblemDescriptionItem class="deposit-modal__item" name="components.common.depositAmountCurrent" :value="modals.deposit.model.sumDep && $parent.formatSum(modals.deposit.model.sumDep, true)" type="card"/>
                                    <ProblemDescriptionItem class="deposit-modal__item" name="components.common.term" :value="modals.deposit.model.depTemp" theme="card"/>
                                    <ProblemDescriptionItem class="deposit-modal__item" name="components.common.depositStatus" :value="getStatus(modals.deposit.model.status)" theme="card"/>
                                    <ProblemDescriptionItem class="deposit-modal__item" name="components.common.channelOfAppeal" :value="modals.deposit.model.isMobile === '1' ? $t('components.common.viaMobileApplication') : $t('components.common.offline')" theme="card"/>
                                    <ProblemDescriptionItem class="deposit-modal__item" name="components.common.prolongation" :value="modals.deposit.model.prolongation === 'N' ? $t('components.common.no') : $t('components.common.yes')" theme="card"/>
                                    <ProblemDescriptionItem class="deposit-modal__item" name="components.common.renewalDate" :value="modals.deposit.model.prolongation === 'N' ? '-' : modals.deposit.model.prolongationDate" theme="card"/>
                                    <ProblemDescriptionItem class="deposit-modal__item" name="components.common.partialWithdrawal" :value="modals.deposit.model.partialWrite === 'Y' ? $t('components.common.yes') : $t('components.common.no')" theme="card"/>
                                    <ProblemDescriptionItem class="deposit-modal__item" name="components.common.earlyClosure" :value="modals.deposit.model.earlyTermination === 'Y' ? $t('components.common.yes') : $t('components.common.no')" theme="card"/>
                                    <ProblemDescriptionItem class="deposit-modal__item" name="components.common.replenishment" :value="modals.deposit.model.replenishment === 'Y' ? $t('components.common.yes') : $t('components.common.no')" theme="card"/>
                                    <ProblemDescriptionItem class="deposit-modal__item" name="components.common.capitalization" :value="modals.deposit.model.capitalization === 'Y' ? $t('components.common.yes') : $t('components.common.no')" theme="card"/>
                                    <ProblemDescriptionItem class="deposit-modal__item" name="components.common.extensionMethod" :value="modals.deposit.model.prolongationSign === 'N' ? $t('components.common.manual') : $t('components.common.auto')" theme="card"/>
                                    <ProblemDescriptionItem class="deposit-modal__item" name="components.common.depositType" :value="modals.deposit.model.depMode === 'S' ? $t('components.common.savings') : $t('components.common.cumulative')" theme="card"/>
                                </div>
                            </template>
                            <div v-else>
                                <div class="block-wrap align-items-end mb-32">
                                    <div>
                                        <label class="d-block">{{ $t("components.common.with") }}</label>
                                        <AppDatePicker @keyup.enter="$parent.loadAccountHistory('deposit')" v-mask="'##.##.####'" value-format="DD.MM.YYYY" size="large" v-model="modals.deposit.account.dateBegin"/>
                                    </div>
                                    <div>
                                        <label class="d-block">{{ $t("components.common.by") }}</label>
                                        <AppDatePicker  @keyup.enter="$parent.loadAccountHistory('deposit')" v-mask="'##.##.####'" value-format="DD.MM.YYYY" size="large" v-model="modals.deposit.account.dateClose"/>
                                    </div>
                                    <AppButton size="medium" :disabled="$parent.loading" @click.prevent="$parent.loadAccountHistory('deposit')">{{ $parent.loading ? $t('common.pleaseWaitWithDots') : $t('components.common.filter') }}</AppButton>
                                    <AppButton class="ml-auto" size="medium" theme="gray" @click="$parent.goToAccountList('deposit')">{{ $t("common.back") }} </AppButton>
                                </div>
                                <div class="deposit-modal__empty" v-if="!depositSpinner && !$parent.historyAccountPagination.length">
                                    <img src="../assets/img/monitoring-select-customer-image.webp" alt="">
                                    <h2>{{ $t("components.common.nothingFound") }}</h2>
                                </div>
                                <AppTable v-if="$parent.historyAccountPagination.length && !depositSpinner" class="deposit-modal__large" :head="historyAccountHead" :data="$parent.historyAccountPagination" theme="admin-page">
                                    <template #cell.debit="{item}">{{ $parent.formatSum(item.debit) }}</template>
                                    <template #cell.credit="{item}">{{ $parent.formatSum(item.credit) }}</template>
                                </AppTable>
                                <div class="mt-20" v-if="modals.deposit.account.history.length > 10">
                                    <p class="mb-0"><input class="paginate_input" @change="changeGrafik($event,'historyAccountPage')" v-model="values.historyAccountPage" type="text" autofocus> {{ $t("components.common.from") }}
                                        <span>{{ modals.deposit.account.history.length  }}</span></p>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #body.3>
                        <div v-if="!depositGrafik && !modals.deposit.schedule.length" class="deposit-modal__body deposit-modal__empty">
                            <img src="../assets/img/monitoring-select-customer-image.webp" alt="">
                            <h2>{{ $t("components.common.nothingFound") }}</h2>
                        </div>
                        <AppTable v-if="tableItems.length > 0" :head="deposistScheduleHead" :data="tableItems" theme="admin-page"  style="height: 460px;">
                            <template #cell.date="{item, rowIndex}">
                                <template v-if="depositGrafik">
                                    <div class="skeleton" :style="{height: '20px' }"></div>
                                </template>
                                <div v-else :ref="'row' + rowIndex">
                                    {{ item.date || "-" }}
                                </div>
                            </template>
                            <template #cell.amount="{item}">
                                <template v-if="depositGrafik">
                                    <div class="skeleton" :style="{height: '20px' }"></div>
                                </template>
                                <template v-else>
                                    {{item.amount ? $parent.formatSum(item.amount, true) : "-" }}
                                </template>
                            </template>
                            <template #cell.proc="{item}">
                                <template v-if="depositGrafik">
                                    <div class="skeleton" :style="{height: '20px' }"></div>
                                </template>
                                <template v-else>
                                    {{ item.proc ? $parent.formatSum(item.proc, true) : "-"}}
                                </template>
                            </template>
                        </AppTable>
                    </template>
                </AppTabs>
            </div>
        </div>
    </ModalWrapper>
</template>
<script>
import AppTable from "./partials/AppTable";
import ModalWrapper from "./partials/ModalWrapper";
import ProblemDescriptionItem from "./partials/ProblemDescriptionItem";
import AppTabs from "./partials/AppTabs";
import PageLoader from "./partials/PageLoader";
import AppButton from "./partials/AppButton";
import AppDatePicker from "./partials/AppDatePicker";
import ListUtils from "../mixins/ListUtils";
import LoanInfoRow from "./modals/partials/LoanInfoRow";
import LoanMixins from "../mixins/LoanMixins";
import AppIcon from "./partials/AppIcon";
import DepositModalHeader from "./modals/partials/DepositModalHeader.vue"

export default {
    components: {
        AppIcon,
        LoanInfoRow,
        AppDatePicker,
        AppButton,
        PageLoader,
        AppTabs,
        ProblemDescriptionItem,
        ModalWrapper,
        AppTable,
        DepositModalHeader
    },
    props: {
        modals: {
            type: Object,
            deposit: {
                type:Object,
                account: {
                    type:Object
                }
            }
        },
        branch: {
            type: Object
        },
        values: {
            type: Object
        }
    },
    mixins: [ListUtils, LoanMixins],
    data () {
        return {
            headerActive: false,
            depositSpinner:false,
            depositGrafik:false,
            depositTab: '1',
            depositTabs: [
                {
                    name: 'components.common.depositInfo',
                    key: '1'
                },
                {
                    name: 'components.common.schedule',
                    key: '3'
                },
            ],
            historyAccountHead: [[
                {
                    name: 'components.common.phone',
                    key: 'numberTrans'
                },
                {
                    name: 'components.common.debitAmount',
                    key: 'debit'
                },
                {
                    name: 'components.common.creditAmount',
                    key: 'credit'
                },
                {
                    name: 'components.common.date',
                    key: 'date'
                },
                {
                    name: 'components.common.note',
                    key: 'purpose',
                    width: '35%'
                },
            ]],
            deposistScheduleHead: [[
                {
                    name: 'components.common.date',
                    key: 'date'
                },
                {
                    name: 'components.common.depositAmount',
                    key: 'amount'
                },
                {
                    name: 'components.common.percent',
                    key: 'proc'
                },
            ]]
        }
    },
    computed: {
        currency() {
            return this.getCurrency(this.modals.deposit.model.currencyCode)
        },

        tableItems() {
            if (this.depositGrafik) {
                const list = [];
                for (let index = 0; index < 10; index++) {
                    list.push({ id: index });
                }
                return list;
            }

            return this.modals.deposit.schedule
        }
    },
    methods: {
        getStatus(status) {
            switch(status) {
                case 1: return 'Открытый';
                default: return 'Закрытый';
            }
        },
        changeGrafik (event,type) {
            if (type === 'grafik') {
                this.$parent.grafikPage = event.target.value
                if (this.$parent.paginateGrafikSchedule.length < event.target.value) {
                    event.target.value = this.$parent.paginateGrafikSchedule.length
                    this.$parent.grafikPage = this.$parent.paginateGrafikSchedule.length
                }
            }
            if (type === 'historyAccountPage') {
                this.$parent.historyAccountPage = event.target.value
                if (this.$parent.historyAccountPagination.length < event.target.value) {
                    event.target.value = this.$parent.historyAccountPagination.length
                    this.$parent.historyAccountPage = this.$parent.historyAccountPagination.length
                }
            }
        },
        onScroll(ev) {
            if(this.depositTab == 3) {
                this.headerActive = ev.target.scrollTop > 164;
            }
        },

        onTabChange(ev) {
            this.$parent.modalTabChanged('deposit', ev);
            this.headerActive = false;
            if(ev == 3) {
                if(this.modals.deposit.schedule.length) {
                    this.scrollToCurrentYear();
                } else {
                    const unwatch = this.$watch(() => this.modals.deposit.schedule, () => {
                        this.scrollToCurrentYear()
                        unwatch();
                    });
                }
            }
        },

        scrollToCurrentYear() {
            this.getToScrollId(this.modals.deposit.schedule, 'date');
        },
    },
    watch: {
        'modals.deposit.schedule': function (val) {
            if (val.length) {
                this.depositGrafik = false
            }
}
    }
}
</script>
<style scoped lang="scss">
.input-group-text {
    width: 100% !important;
    display: block;
    text-align: left;
}
.input-group-prepend {
    width: 220px !important;
}
.input-group-prepend.w-50 {
    width: 50% !important;
}

.my_table {
    overflow: scroll;
    .purpose_text {
        display: block;
        padding-top: 8px;
        padding-bottom: 8px;
        width: 40%;
        max-width: 100%;
        white-space: pre-wrap!important;
    }
    .last_child {
        padding-left: 20px;
    }
}

.form-control:disabled {
    background: unset !important;
}

.card_modal {
    .ant-modal-body {
        padding: 0;
    }

    .modal_header {
        padding: 32px 64px;
    }
}

.agrement {
    span {
        font-size: 16px;
        line-height: 19px;
        color: var(--color-gray-lighten);

        &.active {
            color: var(--color-primary);
        }
    }

    img {
        margin-right: 18px;
        display: inline-block;
    }
}

.page_info {
    margin-right: 33px;

    p {
        font-size: 16px;
        line-height: 19px;
        color: var(--color-gray);

        span {
            font-size: 16px;
            line-height: 19px;
            color: var(--color-gray);
        }
    }
}

.is_error {
    p {
        font-size: 20px;
    }
}

.clickable_account {
    cursor: pointer;
    font-weight: bold !important;

    &:hover {
        color: var(--color-primary-dark);
    }
}

.deposit-modal__body {
    height: 460px;
}

.deposit-modal__block {
    padding: 24px 34px;
    background: #EDF2FF;

    align-items: center;
    column-gap: 72px;
    row-gap: 24px;

    margin: 0 0 32px;
    border-radius: 8px;

    & > * {
        margin: 0;
    }
}

.deposit-modal__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;

    img {
        width: 128px;
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
    }
}
</style>
